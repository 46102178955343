import http from '@/http-common';

export interface Name {
  id: string;
  name: string;
}

export class NameService {
  async getAll(): Promise<Name[]> {
    const result = await http.get('/names');
    return result.data;
  }

  async delete(id: string): Promise<void> {
    return http.delete(`/names/${id}`);
  }
}

export const nameService = new NameService();
