import http from '@/http-common';

const endpointUrl = '/level3words';

export interface Level3Word {
  id: string;
  word: string;
}

export class Level3WordService {
  async create(words: string[]): Promise<Level3Word> {
    const result = await http.post(endpointUrl, words);
    return result.data;
  }

  async getAll(): Promise<Level3Word[]> {
    const result = await http.get(endpointUrl);
    return result.data;
  }

  async delete(word: string): Promise<void> {
    return http.delete(`${endpointUrl}/${word}`);
  }
}

export const level3WordService = new Level3WordService();
