
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { GatheredWord, gatheredWordService } from '@/services/gatheredWord.service';
import { Level3Word, level3WordService } from '@/services/level3Word.service';
import EzProjectSelector from '@/components/projectSelector.vue';
import { GatheredName, gatheredNameService } from '@/services/gatheredName.service';
import { Name, nameService } from '@/services/name.service';

@Options({
  components: {
    EzProjectSelector,
  },
})
export default class LearnPage extends Vue {
  @Prop({ isRequired: false }) projectId?: string;

  gatheredWords: GatheredWord[] | null = null;

  gatheredNames: GatheredName[] | null = null;

  level3words: Level3Word[] | null = null;

  names: Name[] | null = null;

  onDeleteWord(word: string): void {
    console.log(word);
  }

  async created(): Promise<void> {
    if (!this.projectId) {
      return;
    }

    this.gatheredWords = await gatheredWordService.getAll(this.projectId);
    this.gatheredNames = await gatheredNameService.getAll(this.projectId);
    this.level3words = await level3WordService.getAll();
    this.names = await nameService.getAll();
  }

  onProjectChange(projectId: string): void {
    this.$router.push(`/learn/${projectId}`);
  }
}
